.modal-content {
    background-color: transparent !important;
    border: none;

    .auth-container {
        background-color: #16122e;
        border-radius: 20px;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
        height: fit-content;
        margin: auto;
        max-width: 502px;
        opacity: 0.97;
        width: 100%;

        &.register-container {
            height: 600px;
        }
    }

    .auth-logo {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        img {
            height: 36px;
            width: 170px;
        }
    }

    .register-logo {
        padding-right: 60px;
    }

    .auth-padding {
        margin: auto 0;
        padding: 20px 36px;
    }
    .auth-register-padding {
        padding: 36px 12px 0 72px;
    }
}

.modal-fullscreen-sm-down {
    &.modal-dialog {
        max-width: 100% !important;
    }

    .modal-content .auth-container.register-container {
        height: auto;
    }
}

.modal-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.process-message-container {
    background-color: #16122e;
    border-radius: 20px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    height: 172px;
    margin: 0 auto;
    opacity: 0.97;
    padding-top: 24px;
    width: 618px;

    .process-message-text {
        @include flex-center;
        margin-top: 36px;

        span {
            color: #8b8e9c;
            font-size: 0.875em;
        }
    }
}

.singin-text-btns {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 12px 0;

    a {
        color: #8a8e9c;
        font-size: 0.625em;
        text-decoration: underline;
    }
}

.auth-form .checkbox {
    font-weight: 400;
}

.auth-form .form-floating:focus-within {
    z-index: 2;
}

.form-scroll {
    max-height: 77%;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 60px;
}

.form-scroll::-webkit-scrollbar {
    width: 3px; /* width of the entire scrollbar */
}

.form-scroll::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 1px;
    height: 179px;
    opacity: 0.1;
    width: 3px;
}

.form-scroll::-webkit-scrollbar-thumb {
    background-color: #414685;
    height: 40px;
    width: 3px;
}

input[type='date']::-webkit-calendar-picker-indicator {
    border-radius: 4px;
    cursor: pointer;
    filter: invert(0.8);
    margin-right: 2px;
    opacity: 0.6;
}
.termsLink {
    color: #8b52e8;
    text-decoration: none;
}

.country-form {
    .country-label {
        margin: 0;
        opacity: 0.65;
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    }
    .countrySelect {
        border-bottom: 2px solid #585d72;
        border: 0;
        color: #8b8e9c;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        width: 100%;

        &:focus-visible {
            outline: none;
        }
    }

    select {
        background-image: none;
    }
}

.btn-center {
    display: flex;
    margin: 0 auto;
}

.react-datepicker__month-read-view {
    padding: 8px !important;
    margin: 6px !important;
}

.react-datepicker__year-read-view--selected-year {
    padding: 8px !important;
    margin: 6px !important;
}

.react-datepicker__year-option {
    margin: 16px 0;
}

.react-datepicker__month-option {
    margin: 14px 0;
}

.react-datepicker__day,
.react-datepicker__day--selected:focus {
    margin: 5px !important;
}

@include media-breakpoint-down(xs) {
    .modal-content {
        .auth-container {
            padding: 16px;
            .form-scroll {
                padding-right: 16px;
            }
        }
    }
}
