.base-btn {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    color: #ffffff;
    // font-family: 'Rothek SemiBold';
    font-size: 0.875em;
    border: none;
    padding: 6px 30px;
    background-color: #7b4fc1;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        color: #ffffff;
        background-color: #5c498c;
        box-shadow: 0px 15px 20px rgba(94, 74, 146, 0.4);
    }
}

.sign-in-btn,
.log-in-btn,
.auth-sign-in-btn {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    color: #ffffff;
    // font-family: 'Rothek SemiBold';
    font-size: 0.875em;
    border: none;
    padding: 6px 30px;
    background-color: #7b4fc1;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        color: #ffffff;
        background-color: #5c498c;
        box-shadow: 0px 15px 20px rgba(94, 74, 146, 0.4);
    }
}

.log-in-btn-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sing-up-btn {
    width: 180px;
    height: 54px;
    border: none;
    border-radius: 20px;
    background-color: #222222;
    color: #ffffff;
    font-family: 'Rothek Bold';
    font-size: 1.25em;
}

// .auth-sign-in-btn {
//     margin-top: 35px;
//     width: 250px;
//     height: 33px;
//     border: none;
//     border-radius: 16px;
//     background-color: #8b52e8;
//     color: #ffffff;
//     font-family: 'Rothek Bold';
//     font-size: 0.875em;
//     @include flex-center;
//     // margin: 20px auto 0 auto;
//     &:hover {
//         color: #000000;
//     }
// }

.btn-border {
    padding: 6px 24px;
    background-color: transparent;
    border-radius: 12px;
    border: 1px solid rgba($color: #ace994, $alpha: 0.3);
    color: rgba($color: #ffffff, $alpha: 0.3);
    font-size: 0.75em;
    font-weight: 700;

    &:hover {
        background-color: #2f3547;
        color: white;
    }
}

.logout-btn {
    background-color: transparent;
}

.contact-form-button {
    width: 100%;
    height: 46px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    background-color: #030719;
    color: #fff;
    &:hover {
        color: rgb(203, 203, 203);
    }
    &:focus {
        outline: none;
    }
}

.withdraw-btn {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    color: #ffffff;
    font-family: 'Rothek SemiBold';
    font-size: 0.875em;
    border: none;
    padding: 6px 30px;
    background-color: #7b4fc1;

    &:hover {
        background-color: rgba($color: #2e354a, $alpha: 0.8);
        box-shadow: 0 1px 10px rgba(255, 255, 255, 0.2);
    }
    &:disabled {
        background-color: rgba($color: #2e354a, $alpha: 0.8);
        box-shadow: 0 1px 10px rgba(255, 255, 255, 0.2);
    }
}

.not-found-btn {
    position: absolute;
    min-width: 200px;
    left: 29vw;
    top: 60vh;
}

.btn-primary:disabled {
    background-color: #5c498c;
    border-color: #5c498c;
}

// New CSS
.btn-mobile-active {
    background-color: transparent;
    border-color: transparent;
    &:hover {
        background-color: transparent;
        border-color: transparent;
    }
    &:active {
        background-color: transparent;
    }
    &:focus {
        background-color: transparent;
        box-shadow: unset;
        border-color: transparent;
    }
}
@include media-breakpoint-down(xl) {
    .main-sidebar {
        display: none !important;
    }

    .main-navbar.hide-main-navbar {
        display: none;
    }
}

@include media-breakpoint-up(xl) {
    .btn-mobile-active {
        display: none;
    }
}

.offcanvas-bg {
    background-color: #191d2a;
    .nav-link {
        color: #fff;
    }
}
