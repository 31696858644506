/*
   * Sidebar
   */

.sidebar {
    padding: 0;
    margin: 0;
    height: 100%;
    min-height: 100vh;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: rgba($color: #191d2a, $alpha: 0.5);
    display: grid;
    grid-template-columns: minmax(60px, 100px) 1fr;

    .main-sidebar {
        height: 100%;
        background-color: #060b17 !important;

        .sidebar-content {
            margin-top: 30px;
            float: right;

            .sidebar-menu-icon {
                width: 60px;
                height: 60px;
                @include flex-center;

                img {
                    width: 28px;
                    height: 26px;
                }
            }

            .sidebar-item-list {
                margin-top: 200px;
                display: flex;
                align-items: center;
                flex-direction: column;

                .sidebar-item {
                    position: relative;
                    color: #ffffff;
                    font-size: 25px;
                    cursor: pointer;
                    width: 60px;
                    height: 60px;
                    @include flex-center;

                    img {
                        width: 35px;
                        height: 35px;

                        &.custom-icon {
                            width: 72px;
                            height: 74px;
                        }
                    }

                    &.active {
                        color: #aa7bf6;
                        background-color: #191d2a;
                        border-top-left-radius: 50%;
                        border-bottom-left-radius: 50%;
                    }
                }
            }
        }
    }

    .nav {
        margin-top: 20px;
        margin-left: 50px;
    }

    .nav-link {
        color: #949aac;
        font-size: 1.125em;
        font-style: normal;
        letter-spacing: normal;
        line-height: 40px;

        &.active {
            margin-left: -20px;
            color: #ffffff;
            font-family: 'Rothek Bold';
            font-size: 1.375em;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
        }
    }
}

.sidebar-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .profile-img {
        margin-top: 35px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    .profile-name {
        margin-top: 24px;
        color: #ffffff;
        font-family: 'Rothek Bold';
        font-size: 1.5em;
        letter-spacing: normal;
        line-height: normal;
        text-align: center;
    }

    .profile-locality {
        margin-top: 12px;
        color: #5b6173;
        font-size: 0.875em;
        letter-spacing: normal;
        line-height: normal;
        text-align: center;
    }

    .logout-icon {
        margin-left: 8px;
        color: white;
    }

    .divider {
        margin-top: 30px;
        width: 100%;
        height: 1px;
        background-color: rgba($color: #fff, $alpha: 0.2);
    }
}

@media (max-width: 767.98px) {
    .sidebar {
        top: 5rem;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: 100vh;
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #727272;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: 0.75em;
    text-transform: uppercase;
}

.main-sidebar {
    height: 100%;
    background-color: #060b17;
    width: 153px;
    display: flex;
    justify-content: flex-end;

    .sidebar-content {
        // float: right;

        .sidebar-menu-icon {
            width: 60px;
            height: 60px;
            @include flex-center;

            img {
                width: 28px;
                height: 26px;
            }
        }

        .sidebar-item-list {
            margin-top: 200px;
            display: flex;
            align-items: center;
            flex-direction: column;

            .sidebar-item {
                position: relative;
                color: #ffffff;
                font-size: 25px;
                cursor: pointer;
                width: 60px;
                height: 60px;
                @include flex-center;

                img {
                    width: 35px;
                    height: 35px;

                    &.custom-icon {
                        width: 72px;
                        height: 74px;
                    }
                }

                &.active {
                    color: #aa7bf6;
                    background-color: #191d2a;
                    border-top-left-radius: 50%;
                    border-bottom-left-radius: 50%;
                }
            }
        }
    }
}

.width-sidebar {
    width: auto;
    height: 100%;
    .side-nav-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        .nav-link {
            &.active {
                color: #fff;
            }
            &:hover {
                color: #b22f7d;
            }
        }

        .side-item-badge {
            margin-left: -22px;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: solid 1px #aa7bf6;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: #aa7bf6;
            }
        }
    }
}

.bg-aside {
    background-color: #191d2a;
}

.nav {
    padding-left: 2rem;
}

.hamburger-spacing {
    background-color: #191d2a;
}

@include media-breakpoint-only(xs) {
    .nav {
        padding-left: 0;
    }
    .hamburger-spacing {
        padding: 0.5rem 0.1rem;
    }
}

.nav-item-header {
    color: #ffffff;
    font-size: 1.3em;
    font-weight: 700;
    text-decoration: none;

    &:hover {
        color: #ffffff;
    }

    &:link {
        text-decoration: none;
        color: #ffffff;
    }

    &:visited {
        text-decoration: none;
        color: #ffffff;
    }

    &:hover {
        text-decoration: none;
        color: #ffffff;
    }

    &:active {
        text-decoration: none;
        color: #ffffff;
    }
}

.label-list {
    margin-top: 36px;
    margin-left: 20px;
}

.labels-content {
    margin-top: 30%;
    .label-item {
        display: flex;
        align-items: center;
        .label-point {
            width: 16px;
            height: 16px;
            border-radius: 50%;
        }

        .label-title {
            margin: 16px;
            color: #949aac;
            font-size: 1.125em;
            font-weight: 400;
        }
    }
}

.inbox-badge {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #f7658e;
    position: absolute;
    bottom: 11px;
    right: 7px;
}

.alert-item {
    display: flex;
    flex-direction: column;
    margin: 12px 0;
    cursor: pointer;

    .alert-wrapper {
        display: flex;
        align-items: center;

        .alert-subject {
            color: #ffffff;
            font-size: 1.25em;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .alert-icon {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            margin: 8px;
            border: 1px solid #3d4356;
        }
    }

    .alert-date {
        margin-left: 28px;
        color: rgba(255, 255, 255, 0.55);
    }

    .alert-divider {
        margin-top: 10px;
        height: 1px;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
    }
}

.popover {
    color: #fff;

    max-height: 400px;
    overflow-y: scroll;
    .popover-body {
        background-color: rgba(36, 42, 60, 0.95);
    }

    .popover-header {
        background-color: rgba(36, 42, 60, 0.95);
    }
}

.bs-popover-bottom > .popover-arrow::after {
    border-bottom-color: rba(36, 42, 60, 0.95);
}

.bell-wrapper {
    position: relative;

    .bell-badge {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #96c72c;
        position: absolute;
        top: 4px;
        right: 0px;
    }
}

@include media-breakpoint-down(sm) {
    .labels-content {
        margin-top: 10%;
    }
}
