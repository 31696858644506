.landing-container {
    height: 100vh;

    .landing-grid-wrapper {
        display: grid;
        grid-template-rows: auto 1fr auto;
        height: 100vh;
    }
}

.social-container {
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    height: 100%;
    justify-items: center;

    .social-divider {
        background-color: #3a3b3d;
        border-radius: 1px;
        width: 3px;

        &.top {
            margin-bottom: 30px;
            margin-top: 15vh;
        }
        &.bottom {
            margin-bottom: 15vh;
            margin-top: 1.875rem;
        }
    }

    .icon-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .social-icon {
            cursor: pointer;
            height: 30px;
            margin: 10px;
            width: 30px;
        }
    }
}

.landing-header-container {
    margin: 0;
    padding-top: 63px;
    z-index: 9;
}

.home-container {
    align-items: center;
    display: flex;
    min-height: 90vh;
}

.section-container {
    .section-home {
        letter-spacing: 3px;
        text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);

        .first-label {
            color: #ffffff;
            font-size: 1.688em;
            font-weight: 400;
        }

        .second-label {
            color: #fefefe;
            font-size: 4.438em;
            font-weight: bold;
            letter-spacing: 7px;

            span {
                color: #5e4a92;
            }
        }
    }
}

.contact-us {
    background-color: rgba(0, 12, 40, 0.4);
    border-radius: 5px;
    height: 159px;
    margin-top: 8rem;
    width: 856px;
    p {
        color: #ffffff;
        font-size: 1.125em;
        font-weight: 400;
    }
}

.title-text {
    color: #ffffff;
    font-size: 1.688em;
    font-weight: 400;
    letter-spacing: 1px;
}

.email {
    color: #ffffff;
    font-size: 1.125em;
    font-weight: 400;
}

.faq-header {
    color: #ffffff;
    font-size: 1.688em;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 8rem;
    text-align: left;
}

.faq-bg {
    background-color: rgba(0, 12, 40, 0.4);
    border-radius: 5px;
    color: #ffffff;
    font-size: 1.125em;
    font-weight: 400;
    letter-spacing: 0.67px;
    line-height: 24px;
}

.about-us-header {
    color: #ffffff;
    font-size: 1.688em;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 12.5rem;
    text-align: left;
}

.about-card-bg {
    background-color: rgba(0, 12, 40, 0.4);
}

.about-card-title {
    color: #ffffff;
    font-size: 1.125em;
    font-weight: 700;
    letter-spacing: 0.67px;
}

.about-card-description {
    color: #ffffff;
    font-weight: 400;
    letter-spacing: 0.67px;
}

.language-bg {
    background: transparent;
    border: 0;
    color: #989898;
    font-size: 1.313em;
    font-weight: 700;
    letter-spacing: 3.8px;
    text-transform: uppercase;

    &::after {
        margin-left: 23px !important;
    }
    &:hover {
        background: transparent;
    }
}

.dropdown-menu.show {
    background: rgba(0, 12, 40, 0.4);
    .language-bg {
        background: #5e4a92;
    }

    .dropdown-item {
        color: white;
        text-align: center;
        &:hover {
            background: transparent;
            color: #b22f7d;
        }
    }
}

.language-btn {
    width: fit-content;
}

.language-bg.btn-primary:disabled {
    background: transparent;
    color: white;
}

.dropdown-toggle.language-bg {
    &:focus {
        background-color: transparent;
        border: 0px solid transparent;
    }
}

.landing-counter-wrapper {
    @include flex-center;
    flex-direction: column;
    gap: 2.5rem;
    height: 100%;
    .divider {
        background-color: #3a3b3d;
        border-radius: 1px;
        height: 128px;
        width: 3px;
    }

    .counter-title {
        color: #fff;
        font-size: 1.25em;

        &.last {
            color: #6e75ad;
        }
    }
}

.vertical-divider {
    background-color: #3a3b3d;
    border-radius: 1px;
    width: 3px;
}

.benefits-image {
    position: absolute;
    width: 450px;
}

.contact-section {
    color: white;
}

.contact-form {
    background-color: rgba(94, 94, 94, 0.1);
    border-radius: 8px;
    margin-top: 248px;
}

.send-button {
    background-color: #030719;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    width: 100%;
}

.home-bg {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.about-us-bg {
    background-attachment: fixed;
    background-image: url(../../images/about-us-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.products-bg {
    background-attachment: fixed;
    background-image: url(../../images/products-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.benefits-bg {
    background-attachment: fixed;
    background-image: url(../../images/benefits-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
}

.contact-us-bg {
    background-attachment: fixed;
    background-image: url(../../images/contact-us-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.benefits-text-desc {
    color: #ffffff;
    font-weight: 400;
    letter-spacing: 0.67px;
}

.benefits-title-text {
    color: #ffffff;
    font-weight: 700;
    letter-spacing: 0.67px;
}

.about-desc {
    color: #ffffff;
    font-weight: 300;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
}

.about-us-title {
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1.12px;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
}

.about-us-second-title {
    color: #ffffff;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 1.12px;
    margin-top: 8.438rem;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
}

.about-us-second-desc {
    color: #ffffff;
    font-size: 1.688rem;
    font-weight: 400;
    letter-spacing: 1px;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
    text-transform: uppercase;
}

.about-us-bottom-text {
    color: #ffffff;
    font-size: 1.875rem;
    font-weight: 400;
    letter-spacing: 1.12px;
    margin-bottom: 3.5rem;
    margin-top: 12.438rem;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
}

.about-us-container {
    padding-top: 7.75rem;
}

.products-hold {
    color: #ffffff;
    font-size: 53px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 35px;
    text-transform: uppercase;
}

.products-trade {
    color: #ffffff;
    font-size: 53px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 35px;
    margin-top: 120px;
    text-transform: uppercase;
}

.products-hold-desc {
    color: #ffffff;
    font-weight: 400;
    letter-spacing: 0.9px;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
}

.products-trade-desc {
    color: #ffffff;
    font-weight: 400;
    letter-spacing: 0.9px;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
}

.products-euro {
    width: 100%;
}

.posibility-withdraw {
    color: #ffffff;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 1.12px;
    line-height: 106.7px;
    margin-bottom: 12.5rem;
    margin-top: 4.5rem;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
}

.products-startup {
    color: #8b6ac3;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 3.6px;
    margin-bottom: 22px;
    margin-left: 60px;
    opacity: 0.7;
    text-transform: uppercase;
}

.products-established {
    color: #8b6ac3;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 3.6px;
    margin-bottom: 22px;
    margin-left: 30px;
    opacity: 0.7;
    text-transform: uppercase;
}

.products-active {
    color: #8b6ac3;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 3.6px;
    margin-bottom: 22px;
    opacity: 0.7;
    text-transform: uppercase;
}

.products-img {
    margin-bottom: 37px;
}

.startup-desc {
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 400;
    padding: 50px;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
}

.established-desc {
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.67px;
    padding: 50px;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
}

.active-desc {
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.67px;
    padding: 50px;
    text-shadow: 0 6px 26px #000e23;
}

.products-alignment {
    height: 620px;
    text-align: center;
}

.contact-input {
    background-color: rgba($color: #ffffff, $alpha: 0.2);
    border-radius: 8px;
    border: 0;
    color: #ffffff;
    height: 37px;
    padding: 0 12px;
    width: 100%;
    &:focus {
        outline: none;
    }
}

.form-label-title {
    width: 150px;
}

.contact-input-text-area {
    background-color: rgba($color: #ffffff, $alpha: 0.2);
    border-radius: 8px;
    border: 0;
    color: #ffffff;
    height: 146px;
    padding: 12px;
    width: 100%;
    &:focus {
        outline: none;
    }
}

#home-bg {
    height: 100vh;
    left: 0;
    object-fit: cover;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
}

.position-language {
    bottom: 0;
    left: 0;
    position: absolute;
    transform: translateX(130px);
}

// Mobile WEB

.spacing-up {
    padding-top: 250px;
}

.spacing-down {
    padding-bottom: 150px;
}

.spacing-benefits-blocks {
    padding-bottom: 94px;
}

.contact-title {
    color: #ffffff;
    font-size: 27px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 82px;
    margin-top: 150px;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.7);
}
.adress-title {
    color: #ffffff;
    font-weight: 400;
    letter-spacing: 1px;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.7);
}

.adress-description {
    color: #ffffff;
    font-size: 1.125em;
    font-weight: 400;
    letter-spacing: 0.67px;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.85);
}

.email-title {
    color: #ffffff;
    font-weight: 400;
    letter-spacing: 1px;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
}

.email-description {
    color: #ffffff;
    font-size: 1.125em;
    font-weight: 400;
    letter-spacing: 0.67px;
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.85);
}

.lang-dropdown {
    bottom: 0;
    right: 100px;
    position: absolute;
}
.carousel-item {
    transition: transform 0.1s ease-in-out;
}
.header-logo {
    height: 50px !important;
    width: 250px !important;
}

.roadmap-title {
    text-shadow: 0 6px 26px rgba(0, 14, 35, 0.77);
    color: #ffffff;
    font-size: 27px;
    font-weight: 400;
    letter-spacing: 1px;
}

.partners-image {
    width: auto;
    height: 40px;
}

@include media-breakpoint-up(md) {
    .mobile-logo {
        display: none;
    }
}
@include media-breakpoint-down(md) {
    .contact-form {
        margin-top: 0;
    }
    .benefits-image {
        position: unset;
        width: 100%;
    }
    .contact-title {
        margin-bottom: 16px;
        margin-top: 0;
    }
    .spacing-up {
        padding-top: 50px;
    }
    .spacing-down {
        padding-bottom: 50px;
    }
    .spacing-benefits-blocks {
        padding-bottom: 34px;
    }
    .about-us-bottom-text {
        letter-spacing: 0;
    }
    .products-hold-desc {
        font-size: 1.1rem;
        letter-spacing: 0.1px;
    }
    .products-trade-desc {
        font-size: 1.1rem;
        letter-spacing: 0.1px;
    }
    .posibility-withdraw {
        font-size: 1.1rem;
        margin: 0;
    }
    .products-mobile-center {
        display: grid;
        justify-items: center;
    }
    .products-active {
        font-size: 10vw;
        letter-spacing: 0;
    }
    .products-established {
        font-size: 10vw;
        margin-left: 0;
    }
    .products-startup {
        font-size: 10vw;
        margin-left: 0;
    }
    .about-us-bottom-text {
        margin: 0;
    }
    .about-us-second-desc {
        font-size: 1.1rem;
        letter-spacing: 1.1px;
    }
    .about-us-bottom-text {
        font-size: 1.1rem;
        letter-spacing: 1.1px;
    }
    .products-trade {
        margin-top: 30px;
    }
    .section-container {
        .section-home {
            .second-label {
                font-size: 2em;
                span {
                    color: #fff;
                }
            }
        }
    }
    .lang-dropdown {
        left: 0;
    }
    .log-in-btn-wrap {
        justify-content: unset;
        .log-in-btn {
            background-color: transparent;
            box-shadow: unset;
            font-size: 12px;
            height: 45px;
            width: 130px;
        }
    }
    .header-logo {
        display: none;
    }
    .mobile-logo {
        height: 100% !important;
        width: 100% !important;
    }
    .header-auth-btns {
        position: absolute;
        right: 25%;
        top: 20px;
    }
    .section-container .section-home {
        margin: 0;
        position: absolute;
        top: 22%;
        z-index: -1;
    }
}

@include media-breakpoint-down(lg) {
    .landing-header-container {
        margin: 0;
        padding: 0;
    }
    .about-us-img {
        width: 100%;
    }

    .mobile-padding-0 {
        background-color: rgba(8, 55, 111, 0.9);
        padding: 0;
    }
    .mobile-hide {
        display: none;
    }
    .world-map-mobile {
        width: 300px;
    }
    .contact-form {
        margin-bottom: 48px;
    }
}
@include media-breakpoint-up(xl) {
    .products-euro {
        width: 474px;
    }
    .zooming-block {
        margin: 0 auto;
        transition: transform 0.2s; /* Animation */
        z-index: 9;

        &:hover {
            transform: scale(1.1);
        }
    }
}
@include media-breakpoint-between(md, xxl) {
    .products-euro {
        width: 274px;
    }
    .benefits-image {
        position: unset;
        width: 200px;
    }
}

.navbar {
    z-index: 22;
}

.mobile-padding-0 {
    .nav-link {
        color: white !important;
        font-size: 16px;
        white-space: nowrap;
        &:hover {
            color: #b22f7d !important;
        }
    }
}

@include media-breakpoint-down(xs) {
    .products-img {
        width: 250px;
    }
}
