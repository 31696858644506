.circular-wrapper {
    border: 1px solid rgba($color: #997dc7, $alpha: 0.4);
    border-radius: 50%;
    width: 60%;
    height: auto;
    position: relative;
}

.circular-chart {
    display: block;
}

.circle {
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    animation: progress 0.5s ease-out forwards;
}

.cirlce-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15%;

    &:hover {
        .text-content .percent {
            display: none !important;
        }

        .text-content .amount-eur {
            display: block !important;
        }
    }

    .content-border {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba($color: #ef73b7, $alpha: 0.3);
        border-radius: 50%;

        .text-content {
            white-space: nowrap;
            @include flex-center;
            padding: 0px 5px;
            line-height: 1;
            color: #ded7ea;
            font-size: 1.875em;
            cursor: default;
            border-radius: 5px;
            background-color: rgba($color: #01142a, $alpha: 0.3);

            .percent {
                display: block;
            }

            .amount-eur {
                display: none;
            }
        }
    }
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}
