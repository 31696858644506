.profile-container {
    display: grid;
    margin: 50px;
}

.user-info-wrapper {
    background-color: rgba($color: #2f3547, $alpha: 0.8);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
    display: grid;
    grid-template-columns: 50px repeat(4, 1fr);
    padding: 1.5rem;
}

.profile-account-wrapper {
    background-color: rgba($color: #2f3547, $alpha: 0.8);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
    display: grid;
    grid-template-columns: 50px repeat(4, 1fr);
    margin-top: 1rem;
    padding: 1.5rem;
}

.profile-document-wrapper {
    background-color: rgba($color: #2f3547, $alpha: 0.8);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.3);
    display: grid;
    grid-template-columns: 50px 1fr 2fr 1fr;
    margin-top: 1rem;
    padding: 1.5rem;

    .document-preview {
        display: grid;
        place-items: center;
    }
}

.profile-image {
    height: 49px;
    width: 49px;
}

.profile-info {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    .primary-text {
        color: #ffffff;
        font-size: 1em;
    }
    .secondary-text {
        color: rgba($color: #dbf9e1, $alpha: 0.3);
        font-size: 0.875em;
    }

    .additional-text {
        color: rgba($color: #ffffff, $alpha: 0.3);
        font-size: 1em;
        margin-top: 1.625em;
    }
}
.btn-container {
    display: grid;
    place-items: center;
}
