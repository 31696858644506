@font-face {
    font-family: 'Rothek Regular';
    src: url('../fonts/Groteskly Yours - Rothek Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Rothek Bold';
    src: url('../fonts/Groteskly Yours - Rothek Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Rothek SemiBold';
    src: url('../fonts/Groteskly Yours - Rothek SemiBold.otf') format('opentype');
}

@font-face {
    font-family: 'Rothek Thin Italic';
    src: url('../fonts/Groteskly Yours - Rothek Thin Italic.otf') format('opentype');
}
