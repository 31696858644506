.statistic-container {
}

.search-wrapper {
    align-items: center;
    display: flex;
    gap: 30px;
    width: 100%;
}

.grid-container {
    display: grid;
    gap: 20px;
    grid-template-columns: 3fr 1fr;
    margin-top: 20px;
}

.statistic-widgets {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .refferal {
        grid-area: unset;
    }
}

.flag-menu {
    height: 400px;
    overflow-y: scroll;
    width: 300px;
    overflow-x: hidden;

    .dropdown-item {
        text-align: start !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.statistic-list {
    .item-info-container {
        grid-template-columns: repeat(5, 1fr);

        &.two-items {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .client-list-header {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        margin-bottom: 12px;

        div {
            color: #dbf9e1;
            font-size: 12px;
            font-weight: 400;
            text-align: right;
            &:first-child {
                text-align: left;
            }
        }
    }

    .item-portfolio-container {
        padding: 0 10px;
    }

    .item-info-container {
        padding: 5px 0;
    }
}
