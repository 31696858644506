@import './mixins/position';
@import './mixins/media';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/_breakpoints';

@import 'fonts';
// components
@import './components';
// pages
@import './pages';

body {
    margin: 0;
    // font-family: '';
    font-family: 'Rothek Regular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #010515 url('../images/secondary-dashboard-bg.jpg');
    padding-right: 0 !important;
    background-size: auto;
}
// @include respond-below(xxl) {
//     body {
//         font-size: 8px;
//     }
// }

// @include respond-below(md) {
//     body {
//         font-size: 10px;
//     }
// }

@include media-breakpoint-down(1450px) {
    body {
        font-size: 14px !important;
    }
}

.info-icon-size {
    width: 30px;
    height: 30px;
}

input[type='range'] {
    height: 37px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    background: transparent;
}
input[type='range']:focus {
    outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: #6438ab;
    border-radius: 20px;
    border: 0px solid #010101;
}
input[type='range']::-webkit-slider-thumb {
    box-shadow: 2px 2px 10px #000031;
    border: 3px solid #ffffff;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #6438ab;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
    background: #6438ab;
}
input[type='range']::-moz-range-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: #6438ab;
    border-radius: 20px;
    border: 0px solid #010101;
}
input[type='range']::-moz-range-thumb {
    box-shadow: 2px 2px 10px #000031;
    border: 3px solid #ffffff;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #6438ab;
    cursor: pointer;
}
input[type='range']::-ms-track {
    width: 100%;
    height: 11px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
}
input[type='range']::-ms-fill-lower {
    background: #6438ab;
    border: 0px solid #010101;
    border-radius: 40px;
    box-shadow: 1px 1px 1px #000000;
}
input[type='range']::-ms-fill-upper {
    background: #6438ab;
    border: 0px solid #010101;
    border-radius: 40px;
    box-shadow: 1px 1px 1px #000000;
}
input[type='range']::-ms-thumb {
    margin-top: 1px;
    box-shadow: 2px 2px 10px #000031;
    border: 3px solid #ffffff;
    height: 26px;
    width: 26px;
    border-radius: 15px;
    background: #6438ab;
    cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
    background: #6438ab;
}
input[type='range']:focus::-ms-fill-upper {
    background: #6438ab;
}

input[type='range']:disabled {
    /* Disabled Element */
}

input[type='range']:disabled::-webkit-slider-runnable-track {
    /* Disabled slider-runnable-track */
    background-color: #5c498c;
    border-color: #5c498c;
}

input[type='range']:disabled::-moz-range-track {
    /* Disabled slider-range-track */
}

input[type='range']:disabled::-webkit-slider-thumb {
    /* Disabled slider-thumb */
    background-color: #5c498c;
    border-color: #5c498c;
}

input[type='range']:disabled::-moz-range-thumb {
    /* Disabled slider-thumb */
    background-color: #5c498c;
    border-color: #5c498c;
}
