.invest-page-container {
    margin: 0 20px;
}

.invest-grid-container {
    // grid-template-columns: 3fr 1fr;
    // display: grid;
    gap: 22px;
    grid-gap: 22px;

    .crypto-card-body {
        display: grid;
    }
}

.comming-soon-center {
    margin: 0 auto;
}

.invest-widget {
    // background-color: rgba($color: #434c64, $alpha: 0.7);
    // border-radius: 16px;
    // box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    // padding: 16px;

    .invest-card-image {
        width: 136px;
        justify-self: center;
    }

    .invest-label {
        color: #ffffff;
        font-size: 1.125rem;
        font-weight: 700;
    }

    .crypto-wallet-color,
    .bank-detail-color {
        background-color: rgba(15, 25, 34, 0.8);
        border-radius: 8px;
        color: #a5a9b2;
        max-width: max-content;
        padding: 8px;
        width: 250px;
        justify-self: center;
    }

    .invest-range-input {
        display: flex;
        flex-direction: column;
        input {
            width: fit-content;
        }

        input[type='range'] {
            width: 100%;
            margin: 8.55px 0;
            background-color: transparent;
            -webkit-appearance: none;
        }
        input[type='range']:focus {
            outline: none;
        }
        input[type='range']::-webkit-slider-runnable-track {
            background: rgba(100, 56, 171, 0.9);
            border: 2px solid #000000;
            width: 100%;
            height: 7.9px;
            cursor: pointer;
        }
        input[type='range']::-webkit-slider-thumb {
            margin-top: -10.55px;
            width: 25px;
            height: 25px;
            background: #6438ab;
            border: 5px solid rgba(0, 0, 0, 0.5);
            border-radius: 50px;
            cursor: pointer;
            -webkit-appearance: none;
        }
        input[type='range']:focus::-webkit-slider-runnable-track {
            background: #6438ab;
        }
        input[type='range']::-moz-range-track {
            background: rgba(100, 56, 171, 0.9);
            border: 2px solid #000000;
            width: 100%;
            height: 7.9px;
            cursor: pointer;
        }
        input[type='range']::-moz-range-thumb {
            width: 25px;
            height: 25px;
            background: #6438ab;
            border: 5px solid rgba(0, 0, 0, 0.5);
            border-radius: 50px;
            cursor: pointer;
        }
        input[type='range']::-ms-track {
            background: transparent;
            border-color: transparent;
            border-width: 8.55px 0;
            color: transparent;
            width: 100%;
            height: 7.9px;
            cursor: pointer;
        }
        input[type='range']::-ms-fill-lower {
            background: #6438ab;
            border: 2px solid #000000;
        }
        input[type='range']::-ms-fill-upper {
            background: rgba(100, 56, 171, 0.9);
            border: 2px solid #000000;
        }
        input[type='range']::-ms-thumb {
            width: 25px;
            height: 25px;
            background: #6438ab;
            border: 5px solid rgba(0, 0, 0, 0.5);
            border-radius: 50px;
            cursor: pointer;
            margin-top: 0px;
            /*Needed to keep the Edge thumb centred*/
        }
        input[type='range']:focus::-ms-fill-lower {
            background: rgba(100, 56, 171, 0.9);
        }
        input[type='range']:focus::-ms-fill-upper {
            background: #6438ab;
        }
        /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
          how to remove the virtical space around the range input in IE*/
        @supports (-ms-ime-align: auto) {
            /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
            input[type='range'] {
                margin: 0;
                /*Edge starts the margin from the thumb, not the track as other browsers do*/
            }
        }

        // input[type='range'] {
        //     -webkit-appearance: none;
        // }
        // input[type='range']:focus {
        //     outline: none;
        // }
        // input[type='range']::-webkit-slider-runnable-track {
        //     background: linear-gradient(to right, #aa3c78 0%, #7b4fc1 100%);
        //     border-radius: 25px;
        //     border: 0px solid #000101;
        //     box-shadow: 0px 0px 0px #000000;
        //     cursor: pointer;
        //     height: 8px;
        //     width: 100%;
        // }
        // input[type='range']::-webkit-slider-thumb {
        //     -webkit-appearance: none;
        //     background: #ffffff;
        //     border-radius: 25px;
        //     border: 1px solid #7e4ebc;
        //     box-shadow: 0px 0px 0px #000000;
        //     cursor: pointer;
        //     height: 20px;
        //     margin-top: -7;
        //     width: 20x;
        // }
        // input[type='range']:focus::-webkit-slider-runnable-track {
        //     background: linear-gradient(to right, #aa3c78 0%, #7b4fc1 100%);
        // }
        // input[type='range']::-moz-range-track {
        //     background: linear-gradient(to right, #aa3c78 0%, #7b4fc1 100%);
        //     border-radius: 25px;
        //     border: 0px solid #000101;
        //     box-shadow: 0px 0px 0px #000000;
        //     cursor: pointer;
        //     height: 13px;
        //     width: 100%;
        // }
        // input[type='range']::-moz-range-thumb {
        //     background: #ffffff;
        //     border-radius: 20px;
        //     border: 1px solid #7e4ebc;
        //     box-shadow: 0px 0px 0px #000000;
        //     cursor: pointer;
        //     height: 20px;
        //     width: 20px;
        // }
        // input[type='range']::-ms-track {
        //     background: transparent;
        //     border-color: transparent;
        //     color: transparent;
        //     cursor: pointer;
        //     height: 13px;
        //     width: 100%;
        // }
        // input[type='range']::-ms-fill-lower {
        //     background: linear-gradient(to right, #aa3c78 0%, #7b4fc1 100%);
        //     border-radius: 50px;
        //     border: 0px solid #000101;
        //     box-shadow: 0px 0px 0px #000000;
        // }
        // input[type='range']::-ms-fill-upper {
        //     background: linear-gradient(to right, #aa3c78 0%, #7b4fc1 100%);
        //     border-radius: 50px;
        //     border: 0px solid #000101;
        //     box-shadow: 0px 0px 0px #000000;
        // }
        // input[type='range']::-ms-thumb {
        //     background: #ffffff;
        //     border-radius: 10px;
        //     border: 1px solid #000000;
        //     box-shadow: 0px 0px 0px #000000;
        //     cursor: pointer;
        //     height: 20px;
        //     margin-top: 1px;
        //     width: 20px;
        // }
        // input[type='range']:focus::-ms-fill-lower {
        //     background: linear-gradient(to right, #aa3c78 0%, #7b4fc1 100%);
        // }
        // input[type='range']:focus::-ms-fill-upper {
        //     background: linear-gradient(to right, #aa3c78 0%, #7b4fc1 100%);
        // }
    }
    .amount-grid-container {
        display: flex;
        justify-content: space-between;
        text-align: center;

        .amount-item {
            color: #ffffff;
            font-size: 1em;
            text-align: left;
            &:first {
                text-align: left;
            }
            &:last-child {
                text-align: right;
            }
        }
    }

    .invest-btn-wrapper {
        @include flex-center;
        flex-wrap: wrap;
        gap: 10px;

        button {
            background-color: #7b4fc1;
            border-radius: 20px;
            border: none;
            box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
            color: #ffffff;
            font-family: 'Rothek SemiBold';
            font-size: 0.875em;
            padding: 6px 30px;

            &:hover {
                background-color: rgba($color: #2e354a, $alpha: 0.8);
                box-shadow: 0 1px 10px rgba(255, 255, 255, 0.2);
            }
            &:disabled {
                background-color: rgba($color: #2e354a, $alpha: 0.8);
                box-shadow: 0 1px 10px rgba(255, 255, 255, 0.2);
            }
        }
    }
}

.deposit-widget {
    // background-image: linear-gradient(-63deg, #1d72f1 0%, #1ac5fb 100%);
    // display: grid;
    // gap: 12px 12px;
    // grid-template-columns: 2fr 1.5fr 7fr;
    // background-color: rgba($color: #3f92d9, $alpha: 0.4);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    color: #fffefc;
    margin-top: 32px;
    padding: 16px;
    border-radius: 10px;
    background: rgba(123, 79, 193, 0.7);

    .last-deposit {
        // @include flex-center;
        border-left: 1px solid rgba($color: #fffefc, $alpha: 0.2);
        border-right: 1px solid rgba($color: #fffefc, $alpha: 0.2);
        display: flex;
        flex-direction: column;
    }
    .primary-text {
        font-size: 1.125em;
    }

    .secondary-text {
        font-size: 2em;
    }

    .info-text {
        font-size: 1.75em;
    }
}

.withdrow-wrapper {
    @include flex-center;
}

.deposit-history {
    // background-image: linear-gradient(-63deg, #1d72f1 0%, #1ac5fb 100%);
    background-color: rgba($color: #3f92d9, $alpha: 0.4);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    color: #fffefc;
    display: grid;
    gap: 12px 12px;
    grid-template-columns: 2fr 1.5fr 7fr;
    margin-top: 32px;
    padding: 12px 23px 12px 27px;
}

.container-wrapper {
    margin: 0;
    padding: 0 50px 50px 50px;
}
.invest-widgets-grid-container {
    //     'chart chart chart annual'
    //     'chart chart chart history';
    // display: grid;
    // gap: 26px;
    // grid-template-areas:
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-rows: 1.5fr 2fr;
    padding-top: 20px;

    .chart {
        grid-area: chart;
    }
    .annual {
        background-color: #eef1fa;
        border-radius: 10px;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
        grid-area: annual;
        padding: 1.5em;

        .annual-title {
            color: #4f4f4f;
            font-family: 'Rothek SemiBold';
            font-size: 1em;
        }

        .annual-item-list {
            display: flex;
            flex-direction: column;
            margin-top: 1.5rem;

            .annual-item {
                display: grid;
                gap: 20px;
                grid-template-columns: minmax(10%, 24px) auto;
                margin-top: 1rem;

                .item-year {
                    color: #858b9a;
                    font-size: 0.75em;
                }

                .item-progress {
                    align-items: center;
                    display: flex;
                    width: 100%;
                }

                .progress-view {
                    background-color: #cbd1e0;
                    height: 5px;
                    width: 100%;

                    &:hover {
                        background-color: #7b4fc1;
                    }
                }
            }
        }
    }
    .history {
        background-image: linear-gradient(-203.98deg, #0faaa2 0%, #0a889d 100%);
        border-radius: 10px;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
        grid-area: history;
        padding: 1.5em;

        .history-title {
            color: #fffefc;
            font-family: 'Rothek SemiBold';
            font-size: 1em;
        }

        .hsitory-item {
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: space-between;

            .history-item-left {
                color: #ffffff;
                font-family: 'Rothek SemiBold';
                font-size: 1em;
            }
            .history-item-secondary {
                color: rgba($color: #fff, $alpha: 0.7);
                font-size: 0.75em;
            }

            .history-item-right {
                color: #ffffff;
                font-size: 0.75em;
            }
        }

        .history-empty {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 80%;
            justify-content: space-around;
            margin-top: 20px;
        }
        .divider {
            background-color: rgba($color: #000000, $alpha: 0.15);
            height: 1px;
            width: 100%;
        }
    }
}

.invest-chart-wrapper {
    background-color: rgba($color: #2f3547, $alpha: 0.8);
    border-radius: 10px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
    padding: 40px;
}

.invest-referral-list {
    margin-top: 20px;
}

.referral-item {
    align-items: center;
    color: #ffffff;
    display: flex;
    font-family: 'Rothek Bold';
    font-size: 1em;
    justify-content: space-between;
    padding: 20px;
}

.reinvest-container {
    align-items: center;
    background: #2f3547;
    border-radius: 20px;
    padding: 8px;
}

.reinvest-balance-text {
    color: #ffffff;
    font-family: 'Rothek SemiBold';
    font-size: 1em;
}

.reinvest-input {
    flex: 1;
    padding-right: 12px;
}

.mobile-invest-btn {
    background-color: transparent;
    border-color: #484748;
    &:active,
    &:focus,
    &:hover {
        background-color: transparent;
        border-color: transparent;
        box-shadow: unset;
    }
}
.mobile-invest-dropdown {
    width: 100%;
}

.mobile-invest-dropdown {
    background-color: rgba(67, 76, 100, 0.7);
}

.invest-chart-container {
    justify-content: center;
    display: flex;
    height: 100%;
    align-items: center;
    background-color: rgba(67, 76, 100, 0.7);
    border-radius: 10px;
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    padding: 20px;

    img {
        width: 100%;
        height: auto;
    }
}

// .web-invest-section {
//     span {
//         color: #ffffff;
//         font-size: 1.125rem;
//         font-weight: 700;
//         text-align: center;
//     }
//     button {
//         min-width: 100%;
//     }
//     input {
//         padding: 19px 0;
//     }
// }

@include media-breakpoint-down(xxl) {
    .invest-grid-container {
        .crypto-card-body {
            display: initial;
        }
    }
}

@include media-breakpoint-down(lg) {
    .history {
        margin-top: 16px;
    }
}

@include media-breakpoint-down(md) {
    .web-invest-section {
        display: none;
    }
    .invest-widgets-grid-container {
        padding: 0;
        .annual {
            margin-top: 16px;
        }
    }
    .reinvest-container {
        margin: 16px 0;
    }
    .bonus-wrapp-container {
        display: block;
    }
    .reinvest-modal-button {
        margin-top: 16px;
    }

    .invest-chart-container {
        // height: 200px;
        // margin-top: 16px;
    }

    .portfolio-performance-image {
        display: none;
    }

    .portfolio-performance-image-mobile {
        width: 100%;
        height: auto;
    }
}

@include media-breakpoint-up(md) {
    .mobile-invest-section {
        display: none;
    }
    .annual {
        margin-bottom: 16px;
    }
    .portfolio-performance-image-mobile {
        display: none;
    }
}

@include media-breakpoint-down(xs) {
    .mobile-invest-btn {
        width: 100%;
    }
    .crypto-card-body {
        display: initial;
    }
}
